//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.bg-light {
  background-color: #eaedf2 !important;
}

.strong-dark {
  color: #2c3038;
}

.p-5 {
  font-size: 20px;
}

.navbar.scrolled.navbar-dark
  .navbar-brand-dynamic-color
  svg
  [stroke]:not([stroke="none"]),
.navbar.scrolled.navbar-dark .navbar-toggler svg [stroke]:not([stroke="none"]),
.navbar.navbar-toggled-show.navbar-dark
  .navbar-brand-dynamic-color
  svg
  [stroke]:not([stroke="none"]),
.navbar.navbar-toggled-show.navbar-dark
  .navbar-toggler
  svg
  [stroke]:not([stroke="none"]) {
  stroke: #211261;
}
.navbar.scrolled.navbar-dark
  .navbar-brand-dynamic-color
  svg
  [fill]:not([fill="none"]),
.navbar.scrolled.navbar-dark .navbar-toggler svg [fill]:not([fill="none"]),
.navbar.navbar-toggled-show.navbar-dark
  .navbar-brand-dynamic-color
  svg
  [fill]:not([fill="none"]),
.navbar.navbar-toggled-show.navbar-dark
  .navbar-toggler
  svg
  [fill]:not([fill="none"]) {
  fill: #211261;
}

.img-logo {
  width: 18%;
  height: auto;
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 17.5%;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 17%;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 16.5%;
  }
  @media screen and (max-width: 575px) {
    width: 16%;
  }
}

.img-logo-svg {
  width: 10%;
  height: auto;
  margin-right: 2%;
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 7%;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 4%;
    margin-right: 1%;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 5%;
    margin-right: 1%;
  }
  @media screen and (min-width: 541) and (max-width: 575px) {
    width: 5%;
    margin-right: 1%;
  }
  @media (max-width: 575px) {
    width: 5%;
    margin-right: 1%;
  }
  @media (max-width: 300px) {
    width: 8%;
    margin-right: 1%;
  }
}

.img-banner-svg {
  width: 55%;
  height: auto;
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 40%;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 20%;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 25%;
  }
  @media screen and (min-width: 541) and (max-width: 575px) {
    width: 45%;
  }
  @media (max-width: 575px) {
    width: 30%;
  }
  @media (max-width: 300px) {
    width: 45%;
  }
}

.img-logo-svg-fixed {
  width: 7%;
  height: auto;
  margin-right: 2%;
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 6%;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 4%;
    margin-right: 1%;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 5%;
    margin-right: 1%;
  }
  @media screen and (min-width: 541) and (max-width: 575px) {
    width: 4%;
    margin-right: 1%;
  }
  @media (max-width: 575px) {
    width: 6%;
    margin-right: 1%;
  }
  @media (max-width: 300px) {
    width: 10%;
  }
}

.img-banner-svg-fixed {
  width: 35%;
  height: auto;
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 33%;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 20%;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 30%;
  }
  @media screen and (min-width: 541) and (max-width: 575px) {
    width: 25%;
  }
  @media (max-width: 575px) {
    width: 35%;
  }
  @media (max-width: 300px) {
    width: 45%;
  }
}

.navbar-brand {
  flex: 1;
}

.forms {
  @media screen and (min-width: 576px) and (max-width: 767px) {
    margin-top: 20%;
  }
  @media screen and (max-width: 575px) {
    margin-top: 20%;
  }
}

@font-face {
  font-family: "Galano Grotesque";
  src: url(../fonts/Galano_Grotesque_Light.otf) format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url(../fonts/Galano_Grotesque_Light.otf) format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url(../fonts/Galano_Grotesque_Regular.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url(../fonts/Galano_Grotesque_Regular.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url(../fonts/Galano_Grotesque_Regular.otf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url(../fonts/Galano_Grotesque_Semi_Bold.otf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url(../fonts/Galano_Grotesque_Bold.otf) format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Sansation";
  src: url(../fonts/Sansation_Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Sansation";
  src: url(../fonts/Sansation_Bold.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Sansation";
  src: url(../fonts/Sansation_Light.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Sansation";
  src: url(../fonts/Sansation_Bold_Italic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Sansation";
  src: url(../fonts/Sansation_Light_Italic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}
